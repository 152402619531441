<template>
  <div class="main flex flex-column" :element-loading-text="loadingText" v-loading="loadingText">
    <div class="header">
      <div class="content flex flex-v-center flex-between">
        <div style="color: rgb(51, 51, 51);font-size: 21px;font-weight: 500;">{{ $t1(info,'title') || "PTEFIGHTER MOCK TEST" }}
        </div>
        <div class="flex flex-v-center">
          <div v-if="time > 0 || time_limit > 0" class="_32hRoCYz6diVAuCxIhinyP flex flex-v-center bold"><i
              style="color: white;white;margin-top: 4px;"><svg viewBox="0 0 16 16" width="1em" height="1em"
                fill="currentColor">
                <g fill="#FFF" fill-rule="nonzero">
                  <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 2a6 6 0 110 12A6 6 0 018 2z"></path>
                  <path d="M8 3a1 1 0 01.993.883L9 4v3.5h2a1 1 0 01.117 1.993L11 9.5H8a1 1 0 01-1-1V4a1 1 0 011-1z">
                  </path>
                </g>
              </svg></i> <span v-if="time >= 0" style="width:50px;">{{ format_time(time) }}</span> <span
              v-if="time_limit > 0"> / {{
                format_time(time_limit) }}</span>
          </div>
          <div v-if="step == 6 && !showIntroduction"
            class="_3g3mZSWjkrQKp0BObgEAV8 cy-nav-progress-text margin-l flex flex-v-center bold"><i
              style="color: white;margin-top: 4px;"><svg viewBox="0 0 20 14" width="1em" height="1em" fill="currentColor">
                <g fill="#FFF" fill-rule="nonzero">
                  <path
                    d="M18 0a1 1 0 01.117 1.993L18 2H6A1 1 0 015.883.007L6 0h12zM13 6a1 1 0 01.117 1.993L13 8H4a1 1 0 01-.117-1.993L4 6h9zM18 12a1 1 0 01.117 1.993L18 14H6a1 1 0 01-.117-1.993L6 12h12z">
                  </path>
                  <path d="M16 4H4a3 3 0 100 6h12a3 3 0 000-6zM4 5h12a2 2 0 110 4H4a2 2 0 110-4z"></path>
                </g>
              </svg></i>{{ current }} of {{ total }}</div>
        </div>
      </div>
      <div class="bar"></div>
    </div>

    <div class="flex1 body" style="overflow-y: scroll;">
      <div class="content">
        <div v-if="step == 0" style="padding-bottom: 60px;">
          <div style="color: rgb(51, 51, 51); font-size: 20px; font-weight: bold;">Welcome {{ $store.state.user.nickName
          }}
          </div>
          <div style="color: rgb(51, 51, 51); font-size: 14px; margin-top: 20px;">The test is
            approximately 2 hours long.
          </div>
          <img style="margin-top: 24px;" src="/web/image/mock/1.png" alt="">
        </div>

        <div v-if="step == 1" style="padding-bottom: 60px;">
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 20px; font-weight: bold; line-height: 31px;">Headphone
            Check</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;margin:20px 0;">This is
            an opportunity to check that your headset is working correctly.</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">1. Put
            your headset on and adjust it so that it fits comfortably over your ears.</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">2. When
            you are ready, click on the <strong>[Play]</strong> button. You will hear a short recording.</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">3. If you
            do not hear anything in your headphones while the status reads <strong>[Playing]</strong>, raise your hand to
            get the attention
            of the Test Administrator.</p>
          <div style="width: 100%; height: 24px;"></div>

          <mock-player :show-btns="true" src="/web/audio/mock_sample.mp3"></mock-player>


          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;margin-top:20px;">· During
            the practice you will not have <strong>[Play]</strong> and <strong>[Stop]</strong> buttons. The audio
            recording will start playing
            automatically. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">· Please
            do not remove your headset. You should wear it throughout the test.</p>
        </div>


        <div v-if="step == 2" style="padding-bottom: 60px;">
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 20px;  line-height: 21px; font-weight: bold;">
            Microphone Check </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  margin:20px 0; line-height: 21px;">This is
            an opportunity to check that your microphone is working correctly. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">1.Make
            sure your headset is on and the microphone is in the downward position near your mouth. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">2.When
            you are ready, click on the <strong>[Record]</strong> button and say "Testing, testing, one, two, three" into
            the microphone.
          </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">3.After
            you have spoken, click on the <strong>[Stop]</strong> button. Your recording is now complete. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">4.Now
            click on the Playback button. You should clearly hear yourself speaking. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">5.If you
            can not hear your voice clearly, please raise your hand.</p>
          <div style="width: 100%; height: 24px;"></div>

          <mock-recorder :show-btns="true"></mock-recorder>

          <div style="width: 100%; height: 24px;"></div>
          <div style="color: rgb(51, 51, 51); font-size: 14px; ">During the test, you will not have
            <strong>[Record]</strong>, <strong>[Playback]</strong> and <strong>[Stop]</strong> buttons.
          </div>
          <div style="color: rgb(51, 51, 51); font-size: 14px; ">The voice recording will start automatically.</div>
        </div>


        <div v-if="step == 3" style="padding-bottom: 60px;">
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 20px; font-weight: bold; line-height: 21px; ">Keyboard
            Check </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px; margin:20px 0">This is
            an opportunity to check that you have the correct keyboard. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">1. Look
            at the top row of letters on the keyboard. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">2. The
            letters should appear in this order Q W E R T Y. </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">3. If you
            do not have a Q W E R T Y keyboard, raise your hand to get the attention of Test Administrator.</p>
          <div style="width: 100%; height: 24px;"></div><img src="/web/image/mock/2.png" alt="">
          <div style="width: 100%; height: 20px;"></div>
        </div>

        <div v-if="step == 4" style="padding-bottom: 60px;">
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 20px; font-weight: bold; line-height: 21px;">Test
            Introduction</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; margin: 20px 0; line-height: 21px;">This test
            will measure the English Reading, Writing, Listening and Speaking skills that you need in an academic setting.
          </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">- The
            test is divided into 3 parts. Each part may contain a number of sections. The sections are individually timed.
            The timer will be shown in the top right corner of your screen. The number of items in the section will also
            be
            displayed.</p>
          <div style="width: 100%; height: 16px;"></div><img src="/web/image/mock/3.png" alt="">
          <div style="width: 100%; height: 16px;"></div>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">- At the
            beginning of each part you will receive instructions. These will provide details on what to expect in that
            part of the test.</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; line-height: 21px;">- By
            clicking on the Next button at the bottom of each screen you confirm your answer and move to the next
            question. If you click on Next you will not be able to return to the previous question. You will not be able
            to revisit any questions at the end of the test.</p>
          <!-- <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">- You
            will be offered a break of up 10 minutes after Part 2. The break is optional.</p> -->
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">- This
            test makes use of different varieties of English, for example, British, American, Australian. You can answer
            in the standard English variety of your choice.</p>
          <div style="width: 100%; height: 20px;"></div>
          <p style="margin-bottom: 10px; color: rgb(51, 51, 51); font-size: 14px;"></p>
        </div>


        <div v-if="step == 5" style="padding-bottom: 60px;">
          <div style="color: rgb(51, 51, 51); font-size:16px; font-weight: bold;">Read the prompt below. In 25 seconds,
            you must reply in your own words, as naturally and clearly as possible. You have 30 seconds to record your
            response. Your response will be sent together with your score report to the institutions selected by you.
          </div>
          <div style="width: 100%; height: 24px;"></div>
          <div style="color: rgb(51, 51, 51); font-size: 16px;">Please introduce yourself. For example, you could talk
            about one of the following:</div>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 16px; font-weight: 400; line-height: 27px;">- Your
            interests </p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 16px; font-weight: 400; line-height: 27px;">- Your
            plans for future study</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 16px; font-weight: 400; line-height: 27px;">- Why you
            want to study abroad</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 16px; font-weight: 400; line-height: 27px;">- Why you
            need to learn English</p>
          <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 16px; font-weight: 400; line-height: 27px;">- Why you
            chose this test</p>
          <div style="width: 100%; height: 24px;"></div>
          <mock-recorder :beep="true" ref="recorder5" :in-time="25" :time-limit="30"></mock-recorder>
        </div>

        <div v-if="step == 6" style="padding-bottom: 60px;">
          <div v-if="showIntroduction">
            <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 20px; font-weight: bold; line-height: 21px;">You are
              about to begin Part {{ part }} of the exam : {{ source.sort == 'Speaking' ? 'Speaking and Writing' :
                source.sort }}</p>
            <p style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px; margin-top: 20px;  line-height: 21px;">Time
              allowed : <span v-if="source.sort == 'Reading'">29 - 30</span><span
                v-else-if="source.sort == 'Listening'">30
                - 43</span><span v-else>54 - 67</span> minutes</p>
            <p v-if="source.sort == 'Speaking' || source.sort == 'Listening'"
              style="margin: 0px; color: rgb(51, 51, 51); font-size: 14px;  line-height: 21px;">Remember: to put your headphones on before beginning this section</p>
          </div>
          <div v-else>
            <div v-if="source">
              <div class="mt bold tj desc"><strong><em>{{ source.description }}</em></strong></div>
              <Speaking :is-mock="true" v-if="['RA', 'RS', 'DI', 'RL', 'ASQ'].indexOf(source.tag) >= 0" :tag="source.tag"
                ref="holder" :source="source">
              </Speaking>

              <Writing :is-mock="true" v-if="['SWT', 'WE'].indexOf(source.tag) >= 0" :tag="source.tag" ref="holder"
                :source="source">
              </Writing>

              <Reading :is-mock="true" v-if="['FIB-RW', 'MCM-R', 'RO', 'FIB-R', 'MCS-R'].indexOf(source.tag) >= 0"
                :tag="source.tag" ref="holder" :source="source"></Reading>

              <Listening :is-mock="true"
                v-if="['SST', 'MCM-L', 'FIB-L', 'HCS', 'MCS-L', 'SMW', 'HIW', 'WFD'].indexOf(source.tag) >= 0"
                ref="holder" :source="source"></Listening>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="content flex flex-between flex-v-center">
        <div @click="save(0)" class="pointer"><a
            class="_1Zi7JhuKHIgIkocPid5CpT   _2lILS_z7x8OAr8kxjGThjG  AnFKXaFvnq3CUV2x_jtDz cy-save-exit">Save & Exit</a>
        </div>

        <div class="pointer" @click="next()"><a class="_1Zi7JhuKHIgIkocPid5CpT     AnFKXaFvnq3CUV2x_jtDz cy-next">
            {{ sourceIndex == items.length - 1 ? 'Submit' : 'Next' }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Listening from '../components/Listening.vue'
import Reading from '../components/Reading.vue'
import Speaking from '../components/Speaking.vue'
import Writing from '../components/Writing.vue'

import MockPlayer from '../components/MockPlayer.vue'
import MockRecorder from '../components/MockRecorder.vue'

export default {
  components: {
    Speaking, Writing, Reading, Listening, MockPlayer, MockRecorder
  },
  data: function () {
    return {
      total: 0,
      current: 0,
      step: -1,
      id: 0,
      items: [],
      time: 0,
      time_limit: 0,
      sourceIndex: -1,
      source: null,
      timerJob: null,
      contents: [],
      record: null,
      info: {},
      loading: true,
      lastSort: "",
      writingTiming: false,
      part: 0,
      showIntroduction: false,
      loadingText: "loading...",
      saving: false
    }
  },

  mounted() {
    document.onselectstart = function () { return false; }
  },

  destroyed() {
    if (this.timerJob) {
      clearInterval(this.timerJob);
    }
  },


  created() {
    this.loadingText = "loading...";
    this.id = this.$route.query.id;
    this.$http.post("/api/mock/info", { id: this.id }).then((info) => {
      this.info = info;
      this.items = info.items;
      this.record = info.record;

      if (this.record.step) {
        let step = this.record.step;
        let sourceIndex = this.record.source_index;
        if (step == 6 && sourceIndex > 0) {
          this.lastSort = this.items[sourceIndex].sort
          if (this.lastSort == 'Speaking' || this.lastSort == 'Writing') {
            this.part = 1;
          } else if (this.lastSort == 'Reading') {
            this.part = 2;
          } else if (this.lastSort == 'Listening') {
            this.part = 3;
          }
          this.time = this.record.time;
          this.time_limit = this.record.time_limit;
          this.timer()
        }
        this.sourceIndex = sourceIndex;
        this.step = step;
      } else {
        if (this.info.single_type) {
          this.step = 6;
        } else {
          this.step = 0;
        }
      }
      this.$nextTick(() => {
        this.loadingText = "";
      })
    })

  },

  watch: {
    time(val) {
      console.log(val)
    },
    sourceIndex(val) {
      console.log(val)
      if (val >= 0) {
        this.source = this.items[val];
      }
    },
    step(val, oldVal) {
      if (val == 6 && oldVal == -1) {
        console.log(1)
      } else {
        if (this.timerJob) {
          this.time = 0;
          this.time_limit = 0;
          clearInterval(this.timerJob);
        }
      }
      if (val == 0 || val == 4) {
        this.time = 120;
        this.time_limit = 0;
        this.timer();
      } else if (val == 1 || val == 2 || val == 3) {
        this.time = 300;
        this.time_limit = 0;
        this.timer();
      } else if (val == 5) {
        this.time_limit = 55;
        this.time = 55;
        this.timer();
        this.$nextTick(() => {
          this.$refs.recorder5.start();
        })
      } else if (val == 6) {
        if (this.sourceIndex == -1) {
          this.sourceIndex = 0;
        }
      }
    },

    showIntroduction(val) {
      if (val) {
        this.time = 120;
        this.time_limit = 120;
        this.timer();
      } else {
        this.refresh()
      }
    },

    source(val) {
      if (val) {
        if (this.info.single_type == 0 && this.lastSort != val.sort && val.sort != 'Writing') {
          this.part++
          this.showIntroduction = true
        } else {
          this.refresh()
        }
      }
    }
  },
  methods: {
    refresh() {
      let val = this.source;
      if (val.sort == "Speaking" || val.sort == "Reading") {
        if (this.lastSort != val.sort || this.time == 0) {
          this.time = this.info.content[val.sort].time * 60
          this.time_limit = this.info.content[val.sort].time * 60;
          this.timer();
        }
        let items = this.items.filter((item) => {
          return item.sort == val.sort
        })
        let current = items.findIndex((item) => {
          return item.id == val.id
        })
        this.current = current + 1
        this.total = items.length
      } else if (val.sort == "Writing") {
        this.time = val.mock_time * 60
        this.time_limit = val.mock_time * 60;
        this.timer();
        this.current = 1;
        this.total = 1;
      } else if (val.sort == "Listening") {
        if (val.tag == "SST") {
          this.time = val.mock_time * 60
          this.time_limit = val.mock_time * 60;
          this.timer();
          this.current = 1;
          this.total = 1;
        } else {
          if (!this.writingTiming) {
            this.time = this.info.content[val.sort].time * 60
            this.time_limit = this.info.content[val.sort].time * 60;
            this.timer();
            this.writingTiming = true;
          }

          let items = this.items.filter((item) => {
            return item.sort == val.sort && item.tag != "SST"
          })
          let current = items.findIndex((item) => {
            return item.id == val.id
          })
          this.current = current + 1
          this.total = items.length
        }
      }
      this.lastSort = val.sort;
    },

    save(status) {
      if (status == 0) {
        this.$confirm('Do you want to exit?', 'Activity saved', {
          confirmButtonText: 'Exit',
          cancelButtonText: 'Cancel',
          type: 'primary'
        }).then(() => {
          this.submit(status);
        });
      }
    },

    submit(status) {
      this.loadingText = "Submitting...";
      let formData = new FormData();
      formData.append("mock_id", this.id);
      formData.append("status", status);
      formData.append("step", this.step);
      formData.append("source_index", this.sourceIndex);
      formData.append("time", this.time);
      formData.append("time_limit", this.time_limit);
      formData.append("record_id", this.info.record.id);
      this.$http.post("/api/mock/record", formData, {
        'Content-type': 'multipart/form-data'
      }).then(() => {
        this.submitText = "保存成功"
        this.$message({
          message: '保存成功',
          type: 'success'
        });

        this.$router.replace("/main/mocks")
      });
    },


    saveItem(content) {
      this.loadingText = 'Saving...'
      return new Promise((resolve) => {
        let formData = new FormData();

        if (this.source && this.source.tag == 'RA') {
          let ra_content = this.$refs.holder.getRaContent();
          if (ra_content) {
            formData.append("ra_content", ra_content);
          }
        }
        formData.append("record_id", this.info.record.id);
        formData.append("no", this.sourceIndex > -1 ? this.source.no : '#');
        formData.append("source_index", this.sourceIndex);
        if (content) {
          formData.append("content", content);
        }
        this.$http.post("/api/mock/record/item", formData, {
          'Content-type': 'multipart/form-data'
        }).then(() => {
          this.loadingText = ""
          resolve(1)
        });
      })

    },

    next() {
      if (this.step == 5) {

        let content = this.$refs.recorder5.getContent();
        // if (content == null && this.info.type_id == 1) {
        //   this.$alert('You need to finish answer this question before going to the next.', 'Cannot Skip', {
        //     type: "warning",
        //     showClose: false,
        //     center: true,
        //     iconClass: "none",
        //     confirmButtonClass: "mock-btn",
        //     confirmButtonText: 'Close',
        //   });
        //   return;
        // } else {

        this.saveItem(content).then(() => {
          this.step++;
        })
        // }
      } else if (this.step == 6) {
        if (this.showIntroduction) {
          this.showIntroduction = false
          return
        }
        let content = this.$refs.holder.getContent();
        console.log(content)
        if (content == null) {
          this.$alert('You need to finish answer this question before going to the next.', 'Cannot Skip', {
            type: "warning",
            showClose: false,
            center: true,
            iconClass: "none",
            confirmButtonClass: "mock-btn",
            confirmButtonText: 'Close',
          });
          return;
        }

        if (this.sourceIndex == this.items.length - 1) {
          let nums = 0;

          let text = nums > 0 ? `${nums} question is unattempted<br>` : "";
          this.$confirm(`${text}Are you sure you want to submit the activity?`, 'Submit activity', {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            dangerouslyUseHTMLString: true,
            type: 'primary'
          }).then(() => {
            this.saveItem(content).then(() => {
              this.submit(1);
            })
          });
        } else {
          this.saveItem(content).then(() => {
            this.sourceIndex++;
          })
        }
        return;
      } else {
        this.step++;
      }



    },
    timer() {
      if (this.timerJob) {
        clearInterval(this.timerJob);
      }
      this.timerJob = setInterval(() => {
        if (this.time == 0) {
          clearInterval(this.timerJob);
          this.timerJob = null;

          if (this.step == 6) {
            this.$alert('Time\'s up. Please move on to the next section.', 'Time out', {
              type: "info",
              center: true,
              showClose: false,
              iconClass: "none",
              confirmButtonClass: "mock-btn",
              confirmButtonText: 'Next',
              callback: () => {
                this.next();
              }
            });
          } else {
            this.$alert('Time\'s up. Please move on to the next section.', 'Time out', {
              type: "info",
              center: true,
              showClose: false,
              iconClass: "none",
              confirmButtonClass: "mock-btn",
              confirmButtonText: 'Next',
              callback: () => {
                this.next();
              }
            });
          }


        } else {
          this.time--;
        }
      }, 1000)
    },

    format_time(time) {
      let minute = parseInt(time / 60);
      let second = time - minute * 60;
      return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
    },
  },
};
</script>

<style scoped>
._1Zi7JhuKHIgIkocPid5CpT.AnFKXaFvnq3CUV2x_jtDz:hover {
  background-color: #0081a4;
  border-color: #0081a4;
}

.main {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  height: 100vh;
  width: 100vw;
  line-height: 1.5;
}

.header {
  background-color: #bfbabb;
}

.body {
  padding: 24px 0;
  background-color: #fff;
}

.content {
  box-sizing: border-box;
  max-width: 1100px;
  margin: 0 auto;
}

.header .content,
.footer .content {
  padding: 10px 0;
  height: 56px;
}

.bar {
  background-color: #0081a4;
  height: 32px;
  width: 100%;
}

.footer {
  font-size: 14px;
  background-color: #bfbabb;
}

.footer-btn {
  background-color: #0081a4;
  color: #fff;
  height: 32px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  line-height: 32px;
  padding: 0 15px;
  width: 140px;
  cursor: pointer;
}


.ZW5c-QfQR1Zv0HLXcK11y {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jbwHXz0y8H1PrUDSXC58c.tLYr5Aseqiu1IRn8_9fmx {
  border-color: #9badc1;
  background-color: #ffffff;
}

.jbwHXz0y8H1PrUDSXC58c {
  width: 320px;
  border: 2px solid #b8b8b8;
  padding: 16px;
  background-color: #fff;
}




.jbwHXz0y8H1PrUDSXC58c ._2VmPOD5Hw1cHSdVxy1cOyF {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 12px;
}

.jbwHXz0y8H1PrUDSXC58c ._1txEm-klOzftu-Y463O4aq {
  font-size: 14px;
  font-weight: 500;
}

.jbwHXz0y8H1PrUDSXC58c ._1txEm-klOzftu-Y463O4aq>div {
  font-weight: 400;
  padding: 8px 0;
}

.ant-slider {
  box-sizing: border-box;
  color: rgba(0, 0, 0, .65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  height: 12px;
  margin: 14px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}

.jbwHXz0y8H1PrUDSXC58c ._397_o8aGvPzl03bGpbfkwV .ant-slider-rail {
  background-color: #ccc;
}

.jbwHXz0y8H1PrUDSXC58c ._1WyoN5XV-1pR4-4X_dlNB8 {
  margin-top: 20px;
}

._1Zi7JhuKHIgIkocPid5CpT {
  background: #8fd9ee;
  border: 1px solid;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
}

._1Zi7JhuKHIgIkocPid5CpT.AnFKXaFvnq3CUV2x_jtDz {
  border-color: #0081a4;
  background-color: #0081a4;
  color: #fff;
  height: 32px;
  font-weight: 400;
  width: 120px !important;
}

._1Zi7JhuKHIgIkocPid5CpT+._1Zi7JhuKHIgIkocPid5CpT {
  margin-left: 6px;
}

.jbwHXz0y8H1PrUDSXC58c ._397_o8aGvPzl03bGpbfkwV .ant-slider-handle {
  width: 24px;
  height: 24px;
  margin-top: -10px;
  box-shadow: none;
  border: 2px solid #ccc !important;
}

._1Zi7JhuKHIgIkocPid5CpT._2lILS_z7x8OAr8kxjGThjG {
  background-color: #ececeb;
  border-color: #ececeb;
  font-weight: 400;
  color: #333;
}

._1Zi7JhuKHIgIkocPid5CpT.AnFKXaFvnq3CUV2x_jtDz {
  border-color: #0d7490;
  background-color: #0d7490;
  color: #fff;
  height: 32px;
  font-weight: 400;
}

._1Zi7JhuKHIgIkocPid5CpT {
  border-radius: 20px;
  background: #8fd9ee;
  border: 1px solid;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  padding: 0 15px;
}

._3g3mZSWjkrQKp0BObgEAV8 {
  width: 100px;
  text-align: right;
}

._3g3mZSWjkrQKp0BObgEAV8 i,
._32hRoCYz6diVAuCxIhinyP i {
  margin-right: 8px;
}

._1Zi7JhuKHIgIkocPid5CpT._2ZlXUUp4LLwpNQL7fxFLR1 {
  color: #999 !important;
  background: #bce4f4 !important;
  border: 1px solid #999 !important;
}
</style>
