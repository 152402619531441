import { render, staticRenderFns } from "./Mock.vue?vue&type=template&id=0857da74&scoped=true&"
import script from "./Mock.vue?vue&type=script&lang=js&"
export * from "./Mock.vue?vue&type=script&lang=js&"
import style0 from "./Mock.vue?vue&type=style&index=0&id=0857da74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0857da74",
  null
  
)

export default component.exports